import _ from "lodash";

export const getDefaultDraftYear = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
  
    // rollover to new draft year in July
    if (currentMonth > 6)
      return currentYear + 1;
  
    return currentYear;
  };

  export const getDefaultSeason = () => {
    const currentYear = new Date().getFullYear();
    const today = new Date();
  
    const rolleroverDate = new Date("07/20/" + currentYear)
  
    // TODO: update to analytics season next year
    // rollover to new college/pro season July 20th
    if (today < rolleroverDate) return currentYear - 1;
    return currentYear;
  };

  export const getDefaultAnalyticsSeason = () => {
    const currentYear = new Date().getFullYear();
    const today = new Date();
  
    const rolleroverDate = new Date("10/01/" + currentYear)
  
    // rollover to new college/pro WAR/AI season October 1st
    if (today < rolleroverDate) return currentYear - 1;
    return currentYear;
  };

  export const getSeasons = (firstYear: number, lastYear: number, order?: string) => {
    const seasons = [];
    while (firstYear <= lastYear) {
      seasons.push(firstYear);
      firstYear++;
    }
    return _.orderBy(seasons, [(a) => a], [order == undefined ? "asc" : "desc"]);
  };

  export const isPostDraft = () => {
    const currentYear = new Date().getFullYear();
    return currentYear == getDefaultDraftYear();
  }